import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
const cssStyle = {
    width: "100%",
    maxHeight: "13em"

}
export default function LetestBlogSection() {
    const [BlogData, setBlogData] = useState([])
    useEffect(() => {
        fetch("https://blog.seswhrs.org/wp-json/wp/v2/posts?_embed&per_page=3").then(res => res.json()).then(data => {
            setBlogData(data)
        })
    }, [])

    const convertDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();
        return formattedDate + ' ' + formattedTime;

    }

    return (
        <section className="section latest-blog">
            <div className="container">
                <div className="columns is-justify-content-center is-desktop">
                    <div className="column is-7-desktop has-text-centered">
                        <div className="section-title">
                            <span className="h6 text-color">Latest News</span>
                            <h2 className="mt-4 content-title">
                                Latest articles to enrich knowledge
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="columns is-multiline is-justify-content-center">
                    {
                        BlogData.map((item, i) => (
                            <div className="column is-4-desktop is-6-tablet" key={`blogItem_${i}`}>
                                <div className="blog-item">
                                    {
                                        item?._embedded['wp:featuredmedia'] !== undefined ?
                                            <img  style={cssStyle} src={item?._embedded['wp:featuredmedia'][0]?.source_url} alt={item.title.rendered} className="" /> : ""
                                    }
                                    <div className="card-body mt-2">
                                        <span className="text-sm text-color is-uppercase has-text-weight-bold">
                                            {convertDateTime(item.date)}
                                        </span>
                                        <h3 className="mb-3">
                                            <Link to={item.link} target='_blank' className="">
                                                {item.title.rendered}
                                            </Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>

    )
}
