import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon,
    LineIcon,
    TelegramIcon,
    InstapaperIcon,
    TumblrIcon,

} from "react-share";
const shareUrl = window.location;
export default function ShareComponent() {
    return (
        <div className="share-option mt-5 clearfix py-5">
            <ul className="list-inline mb-0">
                <li className="list-inline-item mb-3" style={{display: "flex"}}>
                    <strong>Share Cause:</strong>
                </li>
                <li className="list-inline-item ">
                    <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                </li>
                <li className="list-inline-item ">
                    <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                </li>
                <li className="list-inline-item ">
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </li>
                <li className="list-inline-item ">
                    <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                </li>
                <li className="list-inline-item ">
                    <EmailShareButton url={shareUrl}>
                        <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                </li>
                <li className="list-inline-item ">
                    <TelegramShareButton url={shareUrl}>
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                </li>
                <li className="list-inline-item ">
                    <LineShareButton url={shareUrl}>
                        <LineIcon size={32} round={true} />
                    </LineShareButton>
                </li>
                <li className="list-inline-item ">
                    <InstapaperShareButton url={shareUrl}>
                        <InstapaperIcon size={32} round={true} />
                    </InstapaperShareButton>
                </li> 
                <li className="list-inline-item ">
                    <TumblrShareButton url={shareUrl}>
                        <TumblrIcon size={32} round={true} />
                    </TumblrShareButton>
                </li>
            </ul>
        </div>
    )
}
