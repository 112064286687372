import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AREAOFWORK } from '../../data/CommanData'
import { isMobile } from 'react-device-detect';
export default function HeaderComponent({ data = {}, OpenMenu, setOpenMenu }) {
    const [OpenSubMenu, setOpenSubMenu] = useState({
        status: false,
        type: ""
    })
    const SubMenuClickHandler = (type) => {
        if (isMobile) {
            setOpenSubMenu((v) => ({
                status: true,
                type: type
            }))
        }
    }
    return (
        <header className="navigation">
            {isMobile ? "" : <>
                <div className="header-top">
                    <div className="container">
                        <div className="columns is-gapless is-justify-content-space-between is-align-items-center">

                            <div className="column is-6-desktop is-4-tablet has-text-left-desktop has-text-centered-mobile">
                                <div className="header-top-info" >
                                    <Link to={`tel:${data.contact}`}>
                                        <i className="icofont-phone mr-2" />
                                        <span>{data.contact}</span>
                                    </Link>
                                    <Link to={`mail:${data.email}`}>
                                        <i className="icofont-email mr-2" />
                                        <span>{data.email}</span>
                                    </Link>
                                    {/* {
                                        Array(6).fill(0).map((v, i) => (
                                            <img key={`_ss_${i}`} src={`/assets/images/gov/${i + 1}.jpg`} className="sm-none" alt="gov" style={{ width: "30px", borderRadius: "50%", marginLeft: "2px" }} />

                                        ))
                                    } */}
                                </div>
                            </div>

                            <div className="column is-6-desktop is-8-tablet">
                                <div className="header-top-right has-text-right-tablet has-text-centered-mobile">
                                    {
                                        data?.social && data.social.map((v, i) =>
                                            <Link key={`hs_${i}}`} to={v.link} target="_blank">
                                                <i className={`icofont-${v.icon}`} />
                                            </Link>
                                        )
                                    }
                                    <Link to="donation" className="top-btn">
                                        Donate Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            <nav className="navbar main-nav" >
                <div className="container">
                    <div className="navbar-brand">
                        <Link className="navbar-item p-0 m-0" to="/">
                            <img src="/assets/images/logo.png" alt="logo" />
                        </Link>
                        <button
                            onClick={() => setOpenMenu(v => !v)}
                            type="button"
                            className="navbar-burger burger"
                            data-hidden="true"
                            data-target="navigation"
                        >
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                        </button>
                    </div>
                    <div className="navbar-menu mr-0" id="navigation" style={{ display: OpenMenu && "block" }}>
                        <ul className="navbar-end">
                            <li className="navbar-item active">
                                <NavLink className="navbar-link" to="/">
                                    Home
                                </NavLink>
                            </li>
                            <li className="navbar-item has-dropdown is-hoverable">
                                <NavLink className="navbar-link" onClick={() => SubMenuClickHandler("About")}>
                                    About Us
                                </NavLink>
                                <div className="navbar-dropdown" style={{ display: OpenSubMenu.type === "About" && "block" }}>
                                    <NavLink className="navbar-item" to="/shivalik">
                                        About Shivalik
                                    </NavLink>
                                    <NavLink className="navbar-item" to="/team">
                                        Team
                                    </NavLink>
                                    <NavLink className="navbar-item" to="/partners">
                                        Out Partner
                                    </NavLink>
                                </div>
                            </li>
                            <li className="navbar-item has-dropdown is-hoverable">
                                <NavLink className="navbar-link" onClick={() => SubMenuClickHandler("work")}>
                                    Area of Work
                                </NavLink>
                                <div className="navbar-dropdown" style={{ display: OpenSubMenu.type === "work" && "block" }}>
                                    {
                                        AREAOFWORK.map((v, i) => <NavLink key={`area_${i}`} className="navbar-item" to={`/work-area/${v.toLocaleLowerCase().replaceAll(" ", "-")}`}>
                                            {v}
                                        </NavLink>)
                                    }
                                </div>
                            </li>
                            <li className="navbar-item has-dropdown is-hoverable">
                                <NavLink className="navbar-link" onClick={() => SubMenuClickHandler("Initiative")}>
                                    Our Initiative
                                </NavLink>
                                <div className="navbar-dropdown" style={{ display: OpenSubMenu.type === "Initiative" && "block" }}>
                                    <NavLink className="navbar-item" to="/courses">
                                        Our Courses
                                    </NavLink>
                                    <a className="navbar-item" target='_blank' href="https://blog.seswhrs.org" rel="noreferrer">
                                        Blog / Artical
                                    </a>
                                    <NavLink className="navbar-item" to="gallery">
                                        Gallery
                                    </NavLink>
                                    {/* <NavLink className="navbar-item" to="case-studie">
                                        Case Studies
                                    </NavLink> */}
                                    <NavLink className="navbar-item" to="volunteer">
                                        Volunteer
                                    </NavLink>
                                </div>
                            </li>

                            {/* <li className="navbar-item">
                                <NavLink className="navbar-link" to="event">
                                    Event
                                </NavLink>
                            </li> */}

                            <li className="navbar-item">
                                <NavLink className="navbar-link" to="contact">
                                    Contact
                                </NavLink>
                            </li>
                        </ul>
                        <div className='navbar-brand'>
                            <Link className="navbar-item p-0 m-0" to="/">
                                <img src="/assets/images/logo2.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
