import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CourcesDeatils from './../components/cources/CourcesDeatils';

const imgPath = "/assets/images/cources/"
export default function CoursePage() {
    const { id } = useParams()
    const [CourcesData, setCourcesData] = useState([])
    const [CourceData, setCourceData] = useState({})
    const [BackgroundImg, setBackgroundImg] = useState({
          background: `url(./assets/images/courses-banner.jpg) no-repeat 100% 100%`,
                    backgroundSize: "cover"
    })
    useEffect(() => {
        fetch("/data/cources.json").then(res => res.json()).then(data => {
            if (data.length > 0) {
                setCourcesData(data);
            }
        })
    }, [])
    useEffect(() => {
        if (id !== undefined) {
            const d = CourcesData.find(v => (v.name).toLowerCase() === id.replaceAll("_", " "))
            if (d !== undefined) {
                setCourceData(d)
                setBackgroundImg({
                    background: `url(${imgPath + d.image.main}) no-repeat 100% 100%`,
                    backgroundSize: "cover"
                })
            }
        }
    }, [CourcesData, id])
    return (
        <>
            <section className="page-title" style={BackgroundImg}>
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <h1 className="is-capitalize text-lg " style={{ textTransform: "capitalize" }}> {id !== undefined && id.length > 0 ? id.replaceAll("_", " ") : "All Course"}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {id !== undefined && id.length > 0 ? <CourcesDeatils id={id} data={CourceData} /> :
                <section className="section event">
                    <div className="container">
                        {
                            CourcesData.length > 0 &&
                            <div className="columns is-multiline is-justify-content-center">
                                {CourcesData.map((item, i) =>
                                    <div className="column is-3-desktop is-6-tablet" key={`course_${i}`}>
                                        <div className="card event-item is-shadowless">
                                            <img src={`./assets/images/cources/${item.image.main}`} className="w-100" alt={item.name} style={{ maxHeight: "120px" }} />
                                            <div className="card-body">
                                                <h5>
                                                    <Link to={`/courses/${item.name.replaceAll(" ", "_").toLowerCase()}`} className="is-block">
                                                        {item.name}
                                                    </Link>
                                                </h5>
                                            </div>
                                            <Link
                                                to={`/courses/${item.name.replaceAll(" ", "_").toLowerCase()}`}
                                                className="btn btn-main-2 is-block has-text-centered"
                                            >
                                                Read More
                                            </Link>
                                        </div>
                                    </div>)
                                }
                            </div>
                        }

                    </div>
                </section>}

        </>

    )
}
