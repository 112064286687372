const AREAOFWORK = [
    "Skill development",
    "Disaster Risk Management",
    "Capacity Building",
    "Solid Waste Management",
    "Health and Hygiene",
    "Child Rights",
    "Rural development",

]

export { AREAOFWORK }