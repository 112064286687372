import React from 'react'
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
export default function SlideShow({ data = [], imgpath = "/assets/images/work/" }) {
    return (
        <>
            {
                data.length > 0 &&
                <Zoom scale={1.4}>
                    {data.map((item, i) =>
                        <img key={`slide_img_${i}`} src={imgpath + item} alt='slide' style={{ maxHeight: "320px", width: "100%" }} />
                    )}
                </Zoom>
            }
        </>
    )
}
