import React from 'react'
import { Link } from 'react-router-dom'

export default function Error404Page() {
    return (
        <section className="section 404 border-top">
            <div className="container">
                <div className="column is-justify-content-center">
                    <div className="column lg-10">
                        <div className="error-content has-text-centered">
                            <i className="icofont-error text-lg" />
                            <h2 style={{ fontSize: 120 }}>404!</h2>
                            <p className="mb-6">
                                Sorry! Which page your are looking for this doesn't exist anymore.
                            </p>
                            <Link to="/" className="btn btn-main is-rounded">
                                Go to home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
