import React, { useState, useEffect } from 'react'
import "./testimonial.css"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ResponsiveSettings from './../data/SlideResponcive';
export default function TestimonialSection() {
    const [DataList, setDataList] = useState([])
    useEffect(() => {
        fetch("/data/testimonial.json").then(res => res.json()).then(data => {
            setDataList(data)
        })
    }, [])

    return (
        <section className="section intro">
            <div className="container">
                <div className=" is-justify-content-center is-desktop">
                    <div className="is-7-desktop has-text-centered">
                        <div className="section-title">
                            {/* <span className="h6 text-color"></span> */}
                            <h2 className="mt-4 content-title">
                            Testimonial
                            </h2>
                        </div>
                    </div>
                </div>
                {
                    DataList.length > 0 &&
                    <Slide slidesToScroll={2} slidesToShow={3} indicators={true} responsive={ResponsiveSettings} cssclassName="slide-css">
                        {
                            DataList.map((item, i) => (
                                <div className="twt-card" key={`test_${i}`}>
                                    <div className="face front-face">
                                        <img
                                            src={`/assets/images/testimonial/${item.image}`}
                                            alt={item.name}
                                            className="profile"
                                        />
                                        <div className="pt-3 text-uppercase name">{item.name}</div>
                                        <div className="designation">{item.title}</div>
                                    </div>
                                    <div className="face back-face">
                                        <div className="testimonial">
                                            <i className="icofont-quote-left"></i>
                                            {item.text}
                                            <i className="icofont-quote-right"></i>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </Slide>
                }
            </div>
        </section>
    )
}
