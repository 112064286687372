const displaySize = [320, 360, 375, 412, 414, 540, 720, 750]


const ResponsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 2
        }
    },
    ...displaySize.map(v=>({
        breakpoint: v,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        })),
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

export default ResponsiveSettings;