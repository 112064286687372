import React, { useEffect, useState } from 'react'
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
export default function SliderSection() {
    const [SlideImage, setSlideImage] = useState([])
    useEffect(() => {
        fetch("/data/slidedata.json").then(res => res.json()).then(data => {
            if (data.length > 0) {
                setSlideImage(data);
            }
        })
    }, [])
    return (
        <div>
            {SlideImage.length > 0 &&
                <Zoom scale={1.4} indicators={true}>
                    {
                        SlideImage.map((item, i) =>
                            <div key={`slide_${i}`} className="block has-text-centered p-5" style={{ backgroundSize: "cover", height: "80vh", backgroundImage: `url(/assets/images/slide/${item})`,backgroundPosition:"center" }} alt='' />
                        )
                    }
                </Zoom>
            }


        </div>

    )
}
