import React from 'react'

export default function DonationConfirmation() {
    return (
        <section className="section confirmation border-top">
            <div className="container">
                <div className="columns is-desktop is-justify-content-center">
                    <div className="column is-10-desktop">
                        <div className="confirmation-content has-text-centered">
                            <i className="icofont-check text-lg" />
                            <h2 className="text-lg mt-4 mb-5">Thank You for your donation!</h2>
                            <p>
                                We are pleased to see your contributation.It means a lot. Please{" "}
                                <span className="text-color has-text-weight-bold">
                                    check your inbox
                                </span>{" "}
                                for details
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
