import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { Link } from 'react-router-dom'

export default function PartnnersSlide() {
  const [PartnersDataList, setPartnersDataList] = useState([])
  useEffect(() => {
    fetch("/data/partner.json").then(res => res.json()).then(data => {
      setPartnersDataList(data)
    })
  }, [])
  return (
    <div className="section pt-5">
      <div className="container">
        <div className="columns is-justify-content-center">
          <div className="column is-8-desktop is-12-tablet">
            <div className="content has-text-centered">
              <h2 className=" mb-5">Worldwide Partners</h2>
            </div>
          </div>
        </div>
        <Marquee pauseOnHover autoFill={true} style={{ overflow: "hidden" }}>

          {
            PartnersDataList.length > 0 && PartnersDataList.map((v, i) =>
              <div className="p-5" key={`client_${i}`}>
                <Link to={v.site} target='_blank' className="is-block has-text-centered center" title={v.name}>
                  <img src={`/assets/images/clients/${v.image}`} alt={v.name} style={{ maxWidth: "120px", maxHeight: "80px" }} />
                </Link>
              </div>
            )
          }
        </Marquee>
      </div>
    </div>

  )
}
