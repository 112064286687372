import React, { useContext, useState, useEffect } from 'react'
import { WebInfo } from '../../App';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function DonationPay({ type }) {
    const WebData = useContext(WebInfo);
    const [CountryList, setCountryList] = useState([])
    // const [DonationList, setDonationList] = useState([])
    // useEffect(() => {
    //     fetch("/data/Donationdata.json").then(res => res.json()).then(data => {
    //         setDonationList(data.find(v => v.title.toLowerCase() === type.replaceAll("-", " ").toLowerCase()))
    //     })
    // }, [type])
    useEffect(() => {
        fetch("/data/countrylist.json").then(res => res.json()).then(data => {
            setCountryList(data)
        })
    }, [type])
    // if (/^\d*$/.test(newValue)) {
    //     setValue(newValue);
    //   }

    const [InputValue, setInputValue] = useState({})
    const [ButtonDisable, setButtonDisable] = useState(false)
    const FormSubmitHandler = (event) => {
        event.preventDefault();
        setButtonDisable(true)
        var formdata = new FormData();
        formdata.append('type', 'donner');
        Object.keys(InputValue).forEach(key => {
            formdata.append(key, InputValue[key]);
        });
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch("/mail/email.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    toast.success(result.message)
                    event.target.reset();
                    setInputValue({})
                } else {
                    toast.error(result.message)
                }
            }).catch(error => {
                toast.error("We are facing an error. Please try again later")
            }).finally(() => {
                setButtonDisable(false)
            });
    }

    const InputOnChangeHandler = (event) => {
        const { name, value } = event.target;
        if (name === 'phone') {
            if (/^\d*$/.test(value)) {

            } else {
                return ''
            }
        }
        setInputValue(v => ({ ...v, [name]: value }))

    }
    return (<>
        <section className="section donation">
            <div className="container">
                <div className="columns is-multiline is-desktop">
                    <div className="column is-8-desktop">
                        <div className="donation-wrap">
                            {/* <h2 className="mb-5" style={{ textTransform: "capitalize" }}>{type.replaceAll("-", " ")} </h2> */}
                            {/* <p className="">
                                {DonationList.sortDesc}{DonationList.descptrion}
                            </p> */}

                            {/* <ul className="list-styled">
                                {
                                    DonationList.option && DonationList.option.map((item, i) =>
                                        <li key={`item_${i}`}> {item}</li>)

                                }
                            </ul> */}
                            <form className="donation-form" onSubmit={FormSubmitHandler}>
                                <h2 className="mb-5 mt-6 border-bottom pb-3">Donor Details</h2>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <label className="column is-3-desktop has-text-weight-bold">
                                        Your Name
                                    </label>
                                    <div className="column is-9-desktop">
                                        <input
                                            type="text"
                                            className="input"
                                            id="inputname"
                                            placeholder="Your Name"
                                            name='name'
                                            value={InputValue.name || ''}
                                            onChange={InputOnChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <label className="column is-3-desktop has-text-weight-bold">
                                        Email
                                    </label>
                                    <div className="column is-9-desktop">
                                        <input
                                            type="email"
                                            className="input"
                                            id="inputEmail3"
                                            placeholder="Email"
                                            name='email'
                                            value={InputValue.email || ''}
                                            onChange={InputOnChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <label className="column is-3-desktop has-text-weight-bold">
                                        Phone
                                    </label>
                                    <div className="column is-9-desktop">
                                        <input
                                            type="tel"
                                            className="input"
                                            id="inputphone"
                                            placeholder="Phone"
                                            name='phone'
                                            value={InputValue.phone || ''}
                                            onChange={InputOnChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <label className="column is-3-desktop has-text-weight-bold">
                                        Country
                                    </label>
                                    <div className="column is-9-desktop">
                                        <select id="inputState" className="input" name='country' value={InputValue.country || 'India'} onChange={InputOnChangeHandler}>
                                            <option selected="">Choose Country</option>
                                            {
                                                CountryList.map(item => (
                                                    <option selected={item.code === 'IN' ? true : false} value={item.name}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <label className="column is-3-desktop has-text-weight-bold">
                                        Address
                                    </label>
                                    <div className="column is-9-desktop">
                                        <input
                                            type="text"
                                            className="input"
                                            id="inputname2"
                                            placeholder="Your Name"
                                            name='address'
                                            value={InputValue.address || ''}
                                            onChange={InputOnChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <label className="column is-3-desktop has-text-weight-bold">
                                        City
                                    </label>
                                    <div className="column is-9-desktop">
                                        <input
                                            type="text"
                                            className="input"
                                            id="inputname3"
                                            placeholder="Your City"
                                            name='city'
                                            value={InputValue.city || ''}
                                            onChange={InputOnChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <div className="column is-3-desktop has-text-weight-bold">
                                        <strong>Anonymous donation?</strong>
                                    </div>
                                    <div className="column is-9-desktop">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="gridCheck135"
                                                name='anonymous'
                                                value={InputValue.anonymous || false}
                                                checked={InputValue.anonymous || false}
                                                onChange={InputOnChangeHandler}
                                            />
                                            <label className="form-check-label" htmlFor="gridCheck135">
                                                Hide my personal info from donators list
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns is-gapless is-align-items-center is-multiline">
                                    <div className="column sm-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="gridCheck232"
                                                onChange={InputOnChangeHandler}
                                            />
                                            <label className="form-check-label" htmlFor="gridCheck232">
                                                I give my
                                                <span className="has-text-weight-bold">
                                                    consent for authorized representatives
                                                </span>
                                                of Smile Foundation to contact me occasionally by mobile
                                                and email for informing on the latest developments and
                                                updated offerings.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns is-gapless is-multiline mt-4">
                                    <div className="column sm-10">
                                        <button
                                            disabled={ButtonDisable}
                                            type='submit'
                                            className="btn btn-main-2 is-rounded"
                                        >
                                            Donate Now
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="column is-4-desktop mt-5">
                        <div className="offline-donation">
                            <h4 className="mb-5">
                                In order to make an offline donation we ask that you please follow
                                these instructions:
                            </h4>
                            <ul className="list-unstyled">
                                {/* <li>
                                    Make a check payable to <span style={{ textTransform: "capitalize" }}>"{type.replaceAll("-", " ")}"</span>
                                </li> */}

                                <li>
                                    Account name :
                                    <b> Shivalik Educational Social Welfare Human Resource Society</b>
                                </li>
                                <li>Account Number : <span>011588700000204</span></li>
                                <li>IFSC Code : <span>YESB00001115</span></li>
                                <li>Branch Name : <span>Yes bank Rajpur Road Dehradun Uttarakhand</span></li>
                                <li>
                                    Contact us at :
                                    <span><Link to={`tel:${WebData.contact}`}>{WebData.contact}</Link></span>
                                </li>
                                <li>
                                    Contact us at Phone:
                                    <span><Link to={`tel:${WebData.phone1}`}>{WebData.phone1}</Link>,<Link to={`tel:${WebData.phone2}`}>{WebData.phone2}</Link>,</span>
                                </li>
                                <li>
                                    Assress :<address>{WebData.address}</address>

                                </li>
                            </ul>
                            <p>
                                All contributions will be gratefully acknowledged and are tax
                                deductible.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>

    )
}
