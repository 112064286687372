import React from 'react'
import PartnnersSlide from '../components/partner/PartnnersSlide'
import { toast } from 'react-toastify'
import { useState } from 'react'

export default function VolunteerSection() {
    const [InputValue, setInputValue] = useState({})
    const [ButtonDisable, setButtonDisable] = useState(false)
    const FormSubmitHandler = (event) => {
        event.preventDefault();
        setButtonDisable(true)
        var formdata = new FormData();
        formdata.append('type', 'volunteers');
        Object.keys(InputValue).forEach(key => {
            formdata.append(key, InputValue[key]);
        });
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch("/mail/email.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    toast.success(result.message)
                    event.target.reset();
                    setInputValue({})
                } else {
                    toast.error(result.message)
                }
                setButtonDisable(false)
            }).catch(error => {
                setButtonDisable(false)
                toast.error("We are facing an error. Please try again later")
            });
    }

    const InputOnchnageHandler = (event) => {
        const { name, value } = event.target;
        setInputValue(v => ({ ...v, [name]: value }))
    }
    return (
        <div className="volunteer section">
            <div className="container">
                <div className="columns is-multiline">
                    <div className="column is-7-desktop is-12-tablet">
                        <div className="volunteer-content">
                            <img src="/assets/images/bg/image-5.jpg" alt="" className="" />
                            <h2 className="mb-5 mt-3">
                                We can't help everyone, but everyone can help someone
                            </h2>
                            <p>
                            Though it may not be possible to provide assistance to all, each of us has the capacity to aid another. Even a single individual can contribute and make a difference in the life of another person.
                            </p>
                            <PartnnersSlide />
                        </div>
                    </div>
                    <div className="column is-5-desktop is-12-tablet">
                        <div className="volunteer-form-wrap">
                            <span className="text-white">Join With us</span>
                            <h2 className="mb-6 text-lg text-white">Become A Volunteer</h2>
                            <form className="volunteer-form" onSubmit={FormSubmitHandler}>
                                <div className="mb-4">
                                    <input type="text"
                                        required
                                        name="name"
                                        value={InputValue.name}
                                        onChange={InputOnchnageHandler}
                                        className="input" placeholder="Full Name" />
                                </div>
                                <div className="mb-4">
                                    <input
                                        required
                                        value={InputValue.email}
                                        onChange={InputOnchnageHandler}
                                        name="email"
                                        type="email"
                                        className="input"
                                        placeholder="Emaill Address"
                                    />
                                </div>
                                <div className="mb-4">
                                    <input type="text"
                                        required
                                        name="phone"
                                        value={InputValue.phone}
                                        onChange={InputOnchnageHandler}
                                        className="input" placeholder="Phone Number" />
                                </div>
                                <div className="mb-4">
                                    <input type="text"
                                        required
                                        name="address"
                                        value={InputValue.address}
                                        onChange={InputOnchnageHandler}
                                        className="input" placeholder="Adress " />
                                </div>
                                <div className="mb-4">
                                    <input type="text"
                                        required
                                        name="Occupation"
                                        value={InputValue.Occupation}
                                        onChange={InputOnchnageHandler}
                                        className="input" placeholder="Occupation" />
                                </div>
                                <div className="mb-4">
                                    <textarea
                                        required
                                        name="Message"
                                        value={InputValue.Message}
                                        onChange={InputOnchnageHandler}
                                        cols={30}
                                        rows={6}
                                        className="input"
                                        placeholder="Your Message"
                                    />
                                </div>
                                <button
                                    disabled={ButtonDisable}
                                    type="submit" className="btn btn-main is-rounded mt-5">
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
