import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { WebInfo } from '../App';
import { useContext } from 'react';
export default function ContactUsPage() {
    const data = useContext(WebInfo);
    const [ButtonDisable, setButtonDisable] = useState(false)
    const [InputValue, setInputValue] = useState({})
    const ContactFormSubmitHandler = (event) => {
        event.preventDefault();
        setButtonDisable(true)
        var formdata = new FormData();
        formdata.append('type', 'contact');
        Object.keys(InputValue).forEach(key => {
            formdata.append(key, InputValue[key]);
        });
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch("/mail/email.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    toast.success(result.message)
                    event.target.reset();
                    setInputValue({})
                } else {
                    toast.error(result.message)
                }
                setButtonDisable(false)
            }).catch(error => {
                setButtonDisable(false)
                toast.error("We are facing an error. Please try again later")
            });
    }

    const InputOnchnageHandler = (event) => {
        const { name, value } = event.target;
        setInputValue(v => ({ ...v, [name]: value }))
    }
    return (
        <>
            <section className="page-title bg-1">
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <span className="text-white">Contact Us</span>
                                <h1 className="is-capitalize text-lg">Get in Touch</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {data !== undefined && <section className="section">
                <div className="container">
                    <div className="columns is-justify-content-center is-multiline">
                        <div className="column is-6-desktop is-6-tablet">
                            <div className="contact-content">
                                <h2 className="mb-5">
                                    Contect Information
                                </h2>
                                <ul className="address-block list-unstyled">
                                    <li>
                                        <h6 className="mb-2">Registered Office Address</h6>
                                        {data.address}
                                    </li>
                                    <li>
                                        <h6 className="mb-2">Support Office  Address</h6>
                                        {data.assressSupport}
                                    </li>
                                    <li>
                                        <h6 className="mb-2">email us</h6>
                                        {data.email}<br />
                                        {data.supportemail}
                                    </li>
                                    <li>
                                        <h6 className="mb-2">Phone Number</h6>
                                        <Link to={`tel:${data.contact}`}>{data.contact}</Link>,
                                        <Link to={`tel:${data.phone1}`}>{data.phone1}</Link>,
                                        <Link to={`tel:${data.phone2}`}>{data.phone2}</Link>,
                                        <Link to={`tel:${data.phone3}`}>{data.phone3}</Link>
                                    </li>
                                </ul>
                                <ul className="social-icons list-inline mt-5">
                                    <li>
                                        <h6 className="mb-3">Find us on social media</h6>
                                    </li>
                                    {
                                        data.social && data.social.map((v, i) =>
                                            <li className="list-inline-item" key={`con_${i}`}>
                                                <Link to={v.link} title={v.icon}>
                                                    <i className={`icofont-${v.icon} mr-2`} />
                                                </Link>
                                            </li>)
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="column is-5-desktop is-6-tablet pl-0 pr-0">
                            <iframe title='google map' src={data.map} width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>}
            {/* {JSON.stringify(InputValue)} */}
            <section className="contact-wrap section-bottom">
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <form
                                onSubmit={ContactFormSubmitHandler}
                                className="contact__form"
                                method="post"

                            >
                                <span className="text-color">Send a message</span>
                                <h3 className="text-md mb-5">Contact Form</h3>
                                {/* form message */}
                                <div className="columns is-gapless">
                                    <div className="column is-12">
                                        <div
                                            className="message is-primary contact__msg"
                                            style={{ display: "none" }}
                                        >
                                            <div className="message-body">
                                                Your message was sent successfully.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end message */}
                                <div className="columns is-multiline">
                                    <div className="column is-6-desktop is-6-tablet">
                                        <label>Your Name *</label>
                                        <input name="name" type="text" required className="input" value={InputValue.name} onChange={InputOnchnageHandler} />
                                    </div>
                                    <div className="column is-6-desktop is-6-tablet">
                                        <label>Your Email *</label>
                                        <input name="email" type="email" required className="input" value={InputValue.email} onChange={InputOnchnageHandler} />
                                    </div>
                                    <div className="column is-6-desktop is-6-tablet">
                                        <label>Your Subject *</label>
                                        <input name="subject" type="text" required className="input" value={InputValue.subjecte} onChange={InputOnchnageHandler} />
                                    </div>
                                    <div className="column is-6-desktop is-6-tablet">
                                        <label>Your Phone Number *</label>
                                        <input name="phone" type="tel" required className="input" minLength={10} maxLength={10} value={InputValue.phone} onChange={InputOnchnageHandler} />
                                    </div>
                                    <div className="column is-12">
                                        <label>Your Message *</label>
                                        <textarea
                                            name="message"
                                            required
                                            className="input"
                                            rows={6}
                                            value={InputValue.message}
                                            onChange={InputOnchnageHandler}
                                        />
                                    </div>
                                    <div className="column is-12">
                                        <button
                                            disabled={ButtonDisable}
                                            className="btn btn-main is-rounded"
                                            name="submit"
                                            type="submit"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
