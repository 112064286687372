import React, { } from 'react'
import PartnnersSlide from './../components/partner/PartnnersSlide';
import FeatureSection from './../sesction/FeatureSection';
import PlacementSlide from './../components/partner/PlacementSlide';
const BackgroundImg = {
    background: `url(./assets/images/about/aboutus.jpg) no-repeat 100% 100%`,
    backgroundSize: "cover"
}

export default function AboutSivalic() {


    return (
        <>
            <section className="page-title" style={BackgroundImg} >
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <span className="text-white">who we are</span>
                                <h1 className="is-capitalize text-lg">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section about-page">
                <div className="container">
                    <div className="columns is-align-items-center">
                        <div className=" is-4-desktop is-5-tablet">
                            <div className="about-item-img">
                                <img src="/assets/images/about/aboutus.jpg" alt="" className=" w-100" style={{ border: "5px solid #000" }} />
                            </div>
                        </div>
                        <div className="column is-8-desktop is-7-tablet">
                            <div className="about-item-content pl-5">
                                <h2 className="mb-5 " style={{ textAlign: "justify" }}>
                                    Welcome to Shivalik Educational Social Welfare & Human Resource Society
                                </h2>
                                <b className="is-size-6" style={{ textAlign: "justify" }}>
                                    Shivalik Educational Social Welfare & Human Resource Society has been founded by Mr Uttam Singh Rawat’s entrepreneurial zeal and vision in the year 2010  </b>
                                <p className="mb-2" style={{ textAlign: "justify" }}> Under Society Registration Act 1860 in Dehradun Uttarakhand which through the years have spanned in the fields of Hotel Management, Tourism, Educational, Skill Development, Vocational Courses, logistics and Social Services. With a group of similar aspirations to serve the humanity by imparting knowledge, Educational, training-development and employment Shivalik Educational Social Welfare & Human Resource Society culminated into a group of the professionals from various sectors in the market.
                                </p>

                                <p className="mb-2" style={{ textAlign: "justify" }}>   It all started with a joint venture projects with various organizations and departments. Today, Shivalik is a premier training and development work provider in Uttarakhand, having more than 15,000 trained beneficiaries offering a spectrum of various academic, vocational, skill development and capacity building programs. Working in many different fields Shivalik has fixed its mission - Empowering humanity, Enhancing Life from the year 2010, with its corporate office in Dehradun. Hereinafter, the Group attained successive milestones which are mentioned as below
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FeatureSection />
            <section>
                <div className='container'>
                    <div className="columns is-align-items-center">
                        <div className="column is-8-desktop is-7-tablet">
                            <div className="about-item-content pl-5">
                                <h2 className="mb-5 " style={{ textAlign: "justify" }}>
                                    Key Mile Stones
                                </h2>
                                <p className="mb-2" style={{ textAlign: "justify" }}>
                                    In 2010 we embarked on our first Educational enterprise, a joint venture initiative, Indian Academy of Hospitality Management (IAHM) under Shivalik Educational Social Welfare And Human Resource Society. It is one of the premiere hotel management institutes of Dehradun. Since then the Shivalik has grown impressively and aggressively and has achieved exemplary recognition from government, corporate, academic, and professional world.
                                </p>

                                <p className="mb-2" style={{ textAlign: "justify" }}>  Shivalik Educational Social Welfare & Human Resource Society is a group of dedicated professionals and experienced educators & approved by Govt. of Uttarakhand. Shivalik makes it sure that in there cent era of social development, the profession like social work can touch its highest pitch of aim through well-educated and trained professionals. We believe in quality work and our aim is to provide good quality of social and developmental works.
                                </p>
                                <p className="mb-2" style={{ textAlign: "justify" }}>  Shivalik office campus, institute and training centre’s are developed with cutting edge technology &facilities. The striking feature of Shivalik is well planned and comprehensive infrastructure is provided for both Beneficiaries from the Minority Communities in Uttarakhand, trainees, faculty and staff members. the institute has a well-developed Computer Lab, Library, course related Labs, Audio-Visual Classrooms and other essential resources. This makes studying and training at Shivalik Campus a Unique and once-in-a-Lifetime Experience.
                                </p> <p className="mb-2" style={{ textAlign: "justify" }}>
                                    Presently, social learning and development is one of the most required needs in the communities. With globalization of social development works, India is growing tremendously. And as the social work sector is growing, there is a constant need for skilled professionals in this profession.
                                </p>
                            </div>
                        </div>
                        <div className="is-4-desktop is-5-tablet">
                            <div className="about-item-img border-2" style={{ border: "5px solid #000" }}>
                                <img src="/assets/images/about/key.jpg" alt="" className="w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="column is-12-desktop is-12-tablet">
                        <div className="about-item-content pl-5">
                            <h2 className="mb-5 " style={{ textAlign: "justify" }}>
                                About Training &Placement Cell
                            </h2>
                            <p className="mb-2" style={{ textAlign: "justify" }}>
                                The Institute has proactive placement cell which works laboriously and prepares the student to help them in their Induction process. The Institute has its ongoing tie ups with various leading hotels, restaurants and organization nationwide for placing the Beneficiaries from the Minority Communities in Uttarakhand. So, our Beneficiaries from the Minority Communities in Uttarakhand don’t have to experience the job search grill after they pass out.
                            </p>

                        </div>
                    </div>
                    <div className="columns is-align-items-center">

                        <div className="is-4-desktop is-5-tablet">
                            <div className="about-item-img border-2" style={{ border: "5px solid #000" }}>
                                <img src="/assets/images/about/facalty.jpg" alt="" className="w-100" />
                            </div>
                        </div>
                        <div className="column is-8-desktop is-12-tablet">
                            <div className="about-item-content pl-5">
                                <h2 className="mb-5 " style={{ textAlign: "justify" }}>
                                    Our Faculty
                                </h2>
                                <p className="mb-2" style={{ textAlign: "justify" }}>
                                    The academic or training programs at Shivalik are delivered by highly qualified faculty of Trainers, Master Trainers and Content Developers with extensive academic credential and professional experience. Our faculty consists of a fine community of Instructions, who offer the Beneficiaries from the Minority Communities in Uttarakhand, trainees their expert knowledge, advice as well as their passions for the best professional lives. We also invite high profile visiting faculty, experts from the various Industries, Professions to conduct the best sessions for the Beneficiaries from the Minority Communities in Uttarakhand, trainees, beneficiaries leading to the up-gradation of their skills and knowledge in a timely proactive manner.
                                </p>
                                <p className="mb-2" style={{ textAlign: "justify" }}>
                                    Shivalik institute has also been doing skill development projects for the last 13 years and Shivalik has completed those projects as an implementation partner in Uttarakhand with various non-govt and govt organizations. This includes the non-govt organizations are Info International Educational and Welfare Society, GNG Skill Developers Pvt Ltd, Aadit System and Amass Skill Ventures Pvt Ltd and the govt organizations are MSDE, NSDC, USDMA, and Directorate of Panchayati Raj Uttarakhand.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PartnnersSlide />
            <PlacementSlide />

        </>
    )
}
