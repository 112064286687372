import React from 'react'
import { useParams } from 'react-router-dom'
import DonationList from './../components/donation/DonationList';
import DonationPay from './../components/donation/DonationPay';

export default function DonationPage() {
    const { id } = useParams()
    return (
        <>
            <section className="page-title bg-1">
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <h1 className="is-capitalize text-lg " style={{ textTransform: "capitalize" }}> {id !== undefined && id.length > 0 ? id.replaceAll("-", " ") : "Donation"}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* {id !== undefined && id.length > 0 ? <DonationPay type={id} /> : <DonationList />} */}
            <DonationPay />

        </>
    )
}
