
import HeaderComponent from './components/menubar/HeaderComponent';
import FooterComponent from './components/footer/FooterComponent';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactUsPage from './pages/ContactUsPage';
import DonationPage from './pages/DonationPage';
import VolunteerPage from './pages/VolunteerPage';
import DonationConfirmation from './components/donation/DonationConfirmation';
import Error404Page from './pages/Error404Page';
import { createContext, useEffect, useState } from 'react';
import TeamPage from './pages/TeamPage';
import PartnersPage from './pages/PartnersPage';
import AboutSivalic from './pages/AboutSivalic';
import WorkArea from './pages/WorkArea';
import CoursePage from './pages/CoursePage';
import { isMobile } from 'react-device-detect';
import GalleryComponent from './pages/GalleryComponent';
import { ToastContainer } from 'react-toastify';

export const WebInfo = createContext({});
function RoutesList(WebData) {
  const myRoutes = [
    {
      path: '/',
      exact: true,
      component: <HomePage />,
    }, {
      path: '/contact',
      exact: true,
      component: <ContactUsPage data={WebData} />,
    }, {
      path: '/donation',
      exact: true,
      component: <DonationPage />,
    }, {
      path: '/donation/:id',
      component: <DonationPage />,
    }, {
      path: '/work-area/:id',
      component: <WorkArea />,
    }, {
      path: '/gallery',
      exact: true,
      component: <GalleryComponent />,
    }, {
      path: '/gallery/:id',
      component: <GalleryComponent />,
    }, {
      path: '/volunteer',
      component: <VolunteerPage />,
    }, {
      path: '/confirmation',
      component: <DonationConfirmation />,
    }, {
      path: '/team',
      component: <TeamPage />,
    }, {
      path: '/partners',
      component: <PartnersPage />,
    }, {
      path: '/shivalik',
      component: <AboutSivalic />,
    }, {
      path: '/courses',
      component: <CoursePage />,
    }, {
      path: '/courses/:id',
      component: <CoursePage />,
    }, {
      path: '*',
      component: <Error404Page />,
    },
  ]
  return myRoutes
}
function App() {
  const [OpenMenu, setOpenMenu] = useState(false)
  const [WebData, setWebData] = useState({})
  useEffect(() => {
    fetch("/data/commanData.json").then(res => res.json()).then(data => {
      setWebData(data)
    })
  }, [])

  const MunuHandler = () => {
    if (isMobile) {
      setOpenMenu(false);
    }
  }
  return (
    <WebInfo.Provider value={WebData}>
      <ToastContainer />
      <HeaderComponent data={WebData} OpenMenu={OpenMenu} setOpenMenu={setOpenMenu} />
      <main onClick={MunuHandler}>
        <Routes>
          {
            RoutesList().map((route, index) => (
              <Route
                path={route.path}
                exact={route.exact}
                // component={}
                element={route.component}
                key={`route_${index}`} />
            ))
          }
        </Routes>
      </main>

      <FooterComponent data={WebData} />
    </WebInfo.Provider>
  );
}

export default App;
