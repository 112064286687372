import React from 'react'

export default function FeatureSection() {
    return (
        <section className="section  pb-0">
            <div className="container">
                <div className="columns is-gapless is-multiline">
                    <div className="column is-4-desktop is-4-tablet">
                        <div className="feature-inner">
                            <i className="icofont-home" />
                            <h4>Founded in 2010</h4>
                            <p className="mb-4">Work Since 2010 with Pride!!</p>
                        </div>
                    </div>
                    <div className="column is-4-desktop is-4-tablet">
                        <div className="feature-inner">
                            <i className="icofont-web" />
                            <h4>Our Vission</h4>
                            <p>Empowering Humanity, Enhancing Life.</p>
                        </div>
                    </div>
                    <div className="column is-4-desktop is-4-tablet">
                        <div className="feature-inner">
                            <i className="icofont-users" />
                            <h4>Our Mission</h4>
                            <p>
                                To provide an equal opportunity and platform where everyone can get strength to achieve a prosperous life.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
