import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'

export default function PlacementSlide() {
  const [PartnersDataList, setPartnersDataList] = useState([])
  useEffect(() => {
    fetch("/data/placement.json").then(res => res.json()).then(data => {
      setPartnersDataList(data)
    })
  }, [])
  return (
    <div className="section pt-0">
      <div className="container">
        <div className="columns is-justify-content-center">
          <div className="column is-8-desktop is-12-tablet">
            <div className="content has-text-centered">
              <h2 className="content-title mb-5">Placement Partners</h2>
            </div>
          </div>
        </div>
        <>
          <Marquee pauseOnHover autoFill style={{ overflow: "hidden" }}>
            {
              PartnersDataList.length > 0 && PartnersDataList.map((v, i) =>
                <div className="is-3-desktop is-6-tablet p-5" key={`client_${i}`}>
                  <img src={`/assets/images/placement/${v.image}`} alt={"Placement"} style={{ maxWidth: "120px", maxHeight: "80px" }} />
                </div>
              )
            }
          </Marquee>
        </>
      </div>
    </div>
  )
}
