import React, { useEffect, useState } from 'react'

export default function TeamPage() {
    const [TeamsData, setTeamData] = useState({})
    useEffect(() => {
        fetch("/data/team.json").then(res => res.json()).then(data => {
            setTeamData(data)
        })
    }, [])
    return (
        <>
            <section className="page-title bg-1">
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <h1 className="is-capitalize text-lg">Our Teams</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="is-desktop is-justify-content-center">
                        <div className="is-7-desktop has-text-centered">
                            <div className="section-title">
                                <h2 className="mt-4 content-title">
                                    Board Team
                                </h2>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped  table-hover" style={{ width: "100%" }}>
                        <thead style={{ backgroundColor: "#243fc3" }}>
                            <tr>
                                <th style={{ color: "#fff" }} scope="col">#</th>
                                <th style={{ color: "#fff" }} scope="col">Name</th>
                                <th style={{ color: "#fff" }} scope="col">Occupation</th>
                                <th style={{ color: "#fff" }} scope="col">Designation in Society</th>
                                <th style={{ color: "#fff" }} scope="col">Designation in Organization</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TeamsData.boardTeam !== undefined ? TeamsData.boardTeam.map((v, i) =>
                                <tr key={`t1_${i}`}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{v.Name}</td>
                                    <td>{v.Occupation}</td>
                                    <td>{v.Designation}</td>
                                    <td>{v?.DesignationOrganization}</td>
                                </tr>
                            ) : ""
                            }
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="section team mt-6">
                <div className="container">
                    <div className="is-desktop is-justify-content-center">
                        <div className="is-7-desktop has-text-centered">
                            <div className="section-title">
                                <h2 className="mt-4 content-title">
                                    Core Team
                                </h2>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped table-hover" style={{ width: "100%" }}>
                        <thead style={{ backgroundColor: "#243fc3" }}>
                            <tr>
                                <th style={{ color: "#fff" }} scope="col">#</th>
                                <th style={{ color: "#fff" }} scope="col">Name</th>
                                <th style={{ color: "#fff" }} scope="col">Designation</th>
                                <th style={{ color: "#fff" }} scope="col">Educational Qualification</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TeamsData.coreTeam !== undefined ? TeamsData.coreTeam.map((v, i) =>
                                <tr key={`t1_${i}`}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{v.Name}</td>
                                    <td>{v.Designation}</td>
                                    <td>{v.Qualification}</td>
                                </tr>
                            ) : ""
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </>

    )
}
