import React, { useEffect, useState } from 'react'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import PhotoAlbum from "react-photo-album";
import { Link, useParams } from 'react-router-dom';
const domainName = process.env.REACT_APP_GALLERY_URL;
const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];



export default function GalleryComponent() {
  const { id } = useParams()
  const [GalleryData, setGalleryData] = useState([])
  const [SlidesData, setSlidesData] = useState({})
  const BackgroundImg = {
    background: `url(/assets/images/banner-gallery.jpg) no-repeat 100% 100%`,
    backgroundSize: "cover"
  }
  
  useEffect(() => {
    fetch("/data/gallery.json").then(res => res.json()).then(data => {
      if (data.length > 0) {
        setGalleryData(data);
        if (id !== undefined) {
          const nData = data.find(v => v.path === id)
          setSlidesData(nData)
        }
      }
    })
  }, [id])
  const [index, setIndex] = useState(-1);
  return (
    <>
      <section className="page-title" style={BackgroundImg}>
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="block has-text-centered">
                <h2 className="mt-4 text-white">
                  Our {SlidesData.name} Memory Collection
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mt-5 mb-5'>
        <div className="container-fluid">
          {
            id !== undefined ?
              <>
                <PhotoAlbum
                  layout="rows"
                  photos={Array(SlidesData.count).fill(0).map((photo, i) => {
                    const s = parseInt(i + 1)
                    const n = SlidesData.path + "/" + s + ".jpg";
                    return {
                      src: domainName + n,
                      width: 120,
                      height: 120,
                      srcSet: breakpoints.map((breakpoint) => {
                        return {
                          src: domainName + n,
                          width: breakpoint,
                        };
                      }),
                    };
                  })}
                  targetRowHeight={150}
                  onClick={({ index }) => setIndex(index)}
                />
                <Lightbox
                  open={index >= 0}
                  index={index}
                  close={() => setIndex(-1)}
                  slides={
                    Array(SlidesData.count).fill(0).map((photo, i) => {
                      const s = parseInt(i + 1)
                      const n = SlidesData.path + "/" + s + ".jpg";
                      return {
                        src: domainName + n,
                        width: 120,
                        height: 120,
                        srcSet: breakpoints.map((breakpoint) => {
                          return {
                            src: domainName + n,
                            width: breakpoint,
                          };
                        }),
                      };
                    })
                  }
                />
              </> :

              <div className="columns is-multiline is-justify-content-center">
                {GalleryData.map((item, index) =>
                (
                  <div className="column is-3-desktop is-6-tablet">
                    <Link to={item.path} className="intro-item mb-5 mb-lg-0">
                      <img src={domainName + item.path + "/1.jpg"} alt="" className="w-100" style={{ height: "220px" }} />
                      <h4 className="mt-4 mb-3">{item.name}</h4>
                    </Link>
                  </div>
                ))}
              </div>
          }
        </div>
      </section>

    </>
  );
}
