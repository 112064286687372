import React from 'react'
import VolunteerSection from './../sesction/VolunteerSection';
const imgPath = {
    background: `url(/assets/images/volunteers-banner.jpg) no-repeat 100% 100%`,
    backgroundSize: "cover"
}
export default function VolunteerPage() {

    return (
        <>
            <section className="page-title" style={imgPath}>
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <span className="text-white">Be a Volunteer</span>
                                <h1 className="is-capitalize text-lg">We need your help</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='p-5'>
                <VolunteerSection />
            </div>
        </>

    )
}
