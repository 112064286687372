import React from 'react'
import ShareComponent from '../Share/ShareComponent';
import SlideShow from './../slrdeshow/SlideShow';

export default function CourcesDeatils({ data = {}, id }) {
    return (
        <section className="section cause-single">
            <div className="container">
                <div className="columns is-multiline is-desktop">
                    <div className="column is-10-desktop">
                        <div className="single-details  is-justify-content-center">
                            <h2 className="my-5" style={{ textTransform: "capitalize" }}>{id.replaceAll("_", " ")}</h2>
                            <div className='columns'>

                                <div className="column is-6-desktop">
                                    {data?.image?.allimage.length > 0 &&
                                        <SlideShow data={data.image.allimage} imgpath="/assets/images/cources/" />
                                    }
                                </div>
                                <div className='column is-6-desktop'>
                                    <p className="mt-4">
                                        {data.description}
                                    </p>
                                </div>
                            </div>
                            {/* <ul className="list-styled mt-5">
                                <li>We Are creative organization</li>
                                <li>Trusted funding Resources for poor </li>
                                <li>Quas placeat mollitia nisi voluptatem illum laborum</li>
                            </ul> */}
                            <ShareComponent />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
