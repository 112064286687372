import React, { useEffect, useState } from 'react'
export default function PartnersPage() {
    const [PartnersData, setPartnersData] = useState([])
    useEffect(() => {
        fetch("/data/partner.json").then(res => res.json()).then(data => {
            setPartnersData(data)
        })
    }, [])
    const Calumn4 = ({data}) => {
        return (
            <div className="column is-2-desktop is-5-tablet">
                <div className="about-item-img">
                    <img src={`/assets/images/clients/${data.image}`} alt={data.name} style={{width:"100%"}} />
                </div>
            </div>
        )
    }

    const Calumn8 = ({data}) => {
        return (
            <div className="column is-10-desktop is-7-tablet">
                <div className="about-item-content pl-5">
                    <h2 className="mb-5" >
                        {data.name}
                    </h2>
                    <p className="mb-2" style={{ textAlign: "justify" }}> {data.description}</p>
                </div>
            </div>
        )
    }
    return (
        <>
            <section className="page-title bg-1">
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <h1 className="is-capitalize text-lg">Our Partners</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section about-page">
                <div className="container">
                    {
                        PartnersData.length && PartnersData.map((item, i) => (
                            <div className="columns is-align-items-center m-3">
                                {
                                    i % 2 === 0 ? <>
                                        <Calumn4 data={item} />
                                        <Calumn8 data={item} />
                                    </> :
                                        <>
                                            <Calumn8 data={item} />
                                            <Calumn4 data={item} />
                                        </>
                                }

                            </div>
                        ))
                    }

                </div>
            </section>
        </>
    )
}
