import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import ShareComponent from '../components/Share/ShareComponent';
import SlideShow from './../components/slrdeshow/SlideShow';
const imgPath = "/assets/images/work/"
export default function WorkArea() {
    const myRef = useRef();

    const { id } = useParams()
    const [WorkAreaData, setWorkAreaData] = useState({})
    const [BackgroundImg, setBackgroundImg] = useState({})
    useEffect(() => {
        fetch("/data/work-area.json").then(res => res.json()).then(data => {
            if (data.length > 0) {
                const ndata = data.find(v => v.type === id)
                if (ndata !== undefined) {
                    setWorkAreaData(ndata);
                    setBackgroundImg({
                        background: `url(${imgPath + ndata.images[0]}) no-repeat 100% 100%`,
                        backgroundSize: "cover"
                    })

                }
            }
        })
    }, [id])
    return (
        <>
            <section className="page-title" style={BackgroundImg}>
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="block has-text-centered">
                                <span className="text-white">Work Area</span>
                                <h1 className="is-capitalize text-lg">{WorkAreaData.name}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                Object.keys(WorkAreaData).length > 0 &&
                <section className="section cause-single">
                    <div className="container">
                        <div className="columns is-desktop is-justify-content-center">
                            <div className="column is-10-desktop">
                                <div className="single-details">
                                    <div className='columns'>
                                        {WorkAreaData.images.length > 0 &&
                                            <div className="column is-6-desktop">
                                                <SlideShow data={WorkAreaData.images} />
                                            </div>}
                                        <div className={`column is-${WorkAreaData.images.length > 0 ? 6 : 12}-desktop`} ref={myRef}>
                                            <h2 className="my-5">{WorkAreaData.name}</h2>
                                            <p style={{ textAlign: "justifynpm start" }}>
                                                {WorkAreaData.description}
                                            </p>
                                        </div>
                                    </div>

                                    {
                                        WorkAreaData.title.length > 0 && <>
                                            <h3 className="mb-5">{WorkAreaData.title || ""}</h3>
                                            <ul className="list-styled mt-5">
                                                {
                                                    WorkAreaData.list.map((item, i) =>
                                                        <li key={`item_${i}`}> {item}</li>)
                                                }
                                            </ul>
                                        </>
                                    }
                                    {
                                        WorkAreaData.conclusion.length > 0 && <>
                                            <h3 className="mb-5">Conclusion</h3>
                                            <p className="mt-4">{WorkAreaData.conclusion}</p>
                                        </>
                                    }

                                    <ShareComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}

        </>
    )
}
